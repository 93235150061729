<template>
    <div>
        <div style="margin-top:200px">
            <div class="flex_cen_cen font20 tittlefont">欢 迎 光 临 ~</div>
            <div class="flex_cen_cen font20 tittlefont">welcome</div>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style >

</style>